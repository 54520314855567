.pageBox {
  min-height: 100vh;
}

.mainBox {
  width: 60%;
}

.headerLogo {
  height: 40px;
}

.topBar {
  height: 100px;
}

.loginTopBar {
  margin-right: 15.5rem;
}
.containerCommon {
  margin-right: 4.5rem;
  margin-left: 4.5rem;

  width: unset;
}

.containerStart {
  box-shadow: 0px 0px 16px #00000033;
  border-radius: 10px;
  border-left: 6px solid;

  min-height: 115px;
}

.trialStart {
  border-color: var(--bs-link-color);
}

button.trialBtn,
button.buyNowBtn {
  border-radius: 50px;
  padding: 0.75rem 1.5rem;
  padding-top: 1rem;
}

.contentContainer {
  box-shadow: 0px 0px 40px white;
  border-radius: 5px;
  margin-bottom: 3rem;
}

.sidebarBox {
  background-color: var(--background-blue);
}
.headerWrapper {
  margin-left: 10.5rem;
}
@media (max-width: 500px) {
  .headerWrapper {
    margin-left: 1.5rem;
  }
}

@media (max-width: 1500px) {
  .loginTopBar {
    margin-right: 9rem;
  }
}
@media (max-width: 1380px) {
  .loginTopBar {
    margin-right: 4rem;
  }
}
