.Toastify__toast-container {
  width: 300px;
}

.Toastify__toast--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast--bottom-left {
  bottom: 1em;
  left: 1em;
}
