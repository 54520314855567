.pageBox {
  background-color: var(--background-blue);
  height: 100vh;
}

.contentContainer {
  background-color: var(--bs-white);
  border-radius: 3px;

  width: 500px;
  height: 200px;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 20px;
}