/* START - Helvetica font change */
@font-face {
    font-family: 'Sofia Pro LT Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Sofia Pro LT Pro'), url('fonts/HelveticaNeueLTProBd.otf') format('opentype');
  }
  @font-face {
    font-family: 'Sofia Pro LT Pro';
    font-style: light;
    font-weight: 300;
    src: local('Sofia Pro LT Pro'), url('fonts/HelveticaNeueLTProLt.otf') format('opentype');
  }
  @font-face {
    font-family: 'Sofia Pro LT Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Sofia Pro LT Pro'), url('fonts/HelveticaNeueLTProRoman.otf') format('opentype');
  }
  @font-face {
    font-family: 'Sofia Pro LT Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Sofia Pro LT Pro'), url('fonts/HelveticaNeueLTProMd.otf') format('opentype');
  }
  
  /* body {
    font-family: Open Sans;
    font-weight: 400;
    color: #000000;
  } */
  .containerCustom {
    width: 100% !important;
    max-width: 100%;
  }
  .strongText {
    font-weight: 800;
  }
  header#site-header {
    transition-duration: 1s;
    transition-timing-function: linear;
    box-shadow: 0 3px 5px rgba(0,0,0,.1);
    background: white;
  }
  header#site-header {
    position: fixed !important;
    /* top: 32px; */
    top: 0;
    width: 100%;
    z-index: 99999;
    transition-duration: 0s !important;
    left: 0;
  }
  .no-header-border #site-header {
    border-bottom: none;
  }
  #site-logo {
    float: left;
    height: 100%;
    display: table;
  }
  #site-logo-inner {
    transition: all 0.3s ease-in-out;
  }
  #site-logo #site-logo-inner {
    display: table-cell;
    vertical-align: middle;
    height: 74px;
  }
  #site-logo #site-logo-inner a {
    background-color: transparent !important;
  }
  #site-header #site-logo #site-logo-inner a img {
    transition: all 0.3s ease-in-out;
    height: auto;
    margin-top: 5px;
  }
  .globalWrapper {
    font-family: "Sofia Pro LT Pro", "Open Sans";
  }
  img {
    max-width: 100%;
  }
  .eg-tagline {
    letter-spacing: 1.6px;
    font-size: 0.975rem !important;
    line-height: 20px;
    color: #919191;
  }
  #p-header {
    display: flex;
    position: relative;
    height: 50vw;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-hidden span {
    transition: transform 1s linear;
    animation-name: egTextUp;
    animation-delay: 1.6s;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .overflow-hidden span.btn-span {
    animation-name: egButtonUp;
  }
  #h1-con {
    margin-top: 9vw;
  }
  .egainKB {
    font-size: 4vw;
    line-height: 1.1;
    margin: 9px 0 29px 0;
    z-index: 99;
    position: relative;
    font-weight: 100;
  }
  
  #h-img {
    display: inline-block;
    width: 48.5vw;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  #h-img-con {
    transition: transform 0.46s cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-name: egRevealRight;
    animation-delay: 0.6s;
    transform-origin: left;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mainWrapper#p-header h3 {
    font-size: 23px;
    font-weight: 10;
    margin-bottom: 38px;
  }
  .buttonLink,
  .buttonLink:hover,
  .buttonLink:focus {
    text-decoration: none;
    transition: all 0.3s ease;
  }
  a.eg-btn-black {
    background-color: #121212;
    border: 0.0625rem solid #121212;
    text-align: center;
    border-radius: 3.75rem;
    min-width: 5.25rem;
    padding: 1.325rem 1.975rem;
    font-size: 1.1375rem;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: black;
    display: inline-block;
    box-shadow: inset 16rem 0 0 0 #fff;
    -webkit-transition: ease-out 0.6s;
    -moz-transition: ease-out 0.6s;
    transition: ease-out 0.6s;
    transition-delay: 1s;
  }
  a.eg-btn-black.scroll-ani.animate {
    box-shadow: inset 0 0 0 0 #000;
    color: white;
    transition-delay: 0s;
  }
  a.eg-btn-black.scroll-ani.animate:hover {
    cursor: pointer;
    box-shadow: inset 16rem 0 0 0 #fff;
    color: black;
    transition-delay: 0s;
  }
  #trybuy-btn a.eg-btn-black {
    background-color: white;
    color: black;
  }
  #trybuy-btn a.eg-btn-black.scroll-ani.animate:hover {
    box-shadow: inset 16rem 0 0 0 #000;
    color: white;
  }
  
  @media screen and (min-width: 1800px) {
    .egainKB {
      font-size: 3.7vw;
    }
  }
  @media screen and (min-width: 1900px) {
    .egainKB {
      font-size: 3.5vw;
    }
  }
  @media screen and (min-width: 2000px) {
    #p-header {
      height: 1076px;
    }
    .egainKB {
      font-size: 75px;
    }
    #h-img {
      width: 1000px;
      right: -287px;
    }
  }
  
  @media screen and (min-width: 959px) {
    #h-img-con {
      background-image: url("https://hd.egain.com/images/homepage/web-landing-image-2-1.jpg");
    }
  }
  
  @media screen and (max-width: 959px) {
    main {
      margin-top: 50px !important;
    }
    #site-logo #site-logo-inner {
      height: 57px;
    }
    #site-header #site-logo #site-logo-inner a img {
      margin-left: 20px!important;
      width: 110px;
    }
    #p-header {
      height: 100%;
    }
    #h1-con {
      margin: 11vw 5vw;
      z-index: 99;
    }
    #h1-con .overflow-hidden {
      margin-left: 0px!important;
    }
    .eg-tagline {
      color: black;
      font-size: 0.775rem !important;
    }
    .egainKB {
      font-size: 9.8vw;
      margin: 0;
    }
    .mainWrapper#p-header h3 {
      margin-top: 12px;
    }
    #h-img {
      width: 100%;
      right: 0;
    }
    #h-img-con {
      background-image: url("https://hd.egain.com/images/homepage/home-header-mob-2.jpg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }
    a.eg-btn-black {
      padding: 1.125rem 1.375rem 0.925rem 1.375rem;
      font-size: 0.9375rem;
    }
  }
  @media screen and (max-width: 550px) {
    .overflow-hidden span.btn-span {
      margin-right: 100px;
    }
  }
  
  @keyframes egTextUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-4%);
    }
  }
  @keyframes egTextUpMob {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes egButtonUp {
    0% {
      transform: translateY(140%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes egRevealRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes egRevealLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  