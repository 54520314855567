.customDropdown {
  position: relative;
  display: inline-block;
}

.dropdownMenu {
  position: fixed;
  top: 6%;
  right: 10%;
  z-index: 1000;
  display: none;
  /* Initially hidden; 'show' class will control visibility */
  float: left;
  min-width: 100px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
}

.dropdownMenu.show {
  display: flex;
  flex-direction: column;
  border: none;
}

.dropdownItem {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.dropdownItem:hover,
.dropdownItem:focus {
  color: #1d2124;
  text-decoration: none;
  background-color: #f8f9fa;
}

.ellipsisToggle {
  border: none;
  padding: 0;
  font-size: 24px;
  position: absolute;
  right: 15px;
  bottom: -15px;
  text-decoration: none;
}

.dropdownButton {
  margin-bottom: 5px;
  font-size: 12px;
}