.pageBox {
  min-height: 100vh;
  position: relative;
  background: #e3f4ff;
}

.topBar {
  height: 84px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: var(--bs-white);
}

.headerLogo {
  height: 38px;
}

.largeContainer {
  background-color: var(--bs-white);
  border-radius: 4px;
  border: 1px solid var(--bs-gray-200);
  margin: 0 25rem;
  box-shadow: 0px 0px 12px 0px white;
  padding-left: 3rem;
  padding-right: 3rem;
}
.profileUserName {
  font-family: 'Sofia Pro Black', sans-serif;
  font-size:18px;
}

.smallContainer {
  background-color: var(--bs-white);
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 1100px;
  max-height: 570px;
}

.profileLogo {
  background-color: #d8e1f5;
  border: none;
  border-radius: 50%;
  height: 50px;
  color: #303a52;
  font-weight: bolder;
  font-size: 20px;
  width: 50px;
  padding-top: 8px;
  margin-left: 20px;
  text-align: center;
}
.marginX {
  margin: 0 30rem;
  width: 100%;
}
.announcements {
  width: 100%;
  height: 480px;
  background: white;
  padding: 0 15.5rem;
}
@media (max-width: 1500px) {
  .announcements {
    height: 400px;
  }
}
@media (max-width: 500px) {
  .announcements {
    padding: 0 1.5rem;
  }
}
.announcementsLogo {
  height: auto;
  position: relative;
  top: 6rem;
  width: 100%;
  z-index: 1;
}
.announcementContainer {
  display: flex;
}
.announcementTextContainer {
  margin: 5rem 0px;
  width: 51%;
}

.easyFooter {
  margin: 0 30rem;
  text-align: end;
  margin-bottom: 1rem;
}
.easyLogo {
  margin-bottom: 10px;
}

.announcementTitle {
  font-size: 3.5rem;
  font-weight: 600;
  font-family: 'Sofia Pro Bold', sans-serif;
  color: #000000;
}
.announcementContent {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
@media (max-width: 1900px) {
  .announcementTitle {
    font-size: 3rem;
  }
  .announcementContent {
    font-size: 16px;
  }
}
@media (max-width: 1670px) {
  .announcementTitle {
    font-size: 2.75rem;
  }
  .announcementContent {
    font-size: 16px;
  }
}
@media (max-width: 1570px) {
  .announcementTitle {
    font-size: 2.5rem;
  }
  .announcementContent {
    font-size: 16px;
  }
  .announcements {
    height: 380px;
  }
}
@media (max-width: 1470px) {
  .announcementTitle {
    font-size: 2rem;
  }
  .announcementContent {
    font-size: 16px;
  }
  .announcements {
    height: 350px;
  }
}
@media (max-width: 1275px) {
  .announcementTitle {
    font-size: 1.75rem;
  }
  .announcementContent {
    font-size: 13px;
  }
  .announcements {
    height: 370px;
  }
}
@media (max-width: 1180px) {
  .announcementTitle {
    font-size: 1.5rem;
  }
  .announcementContent {
    font-size: 13px;
  }
  .announcements {
    height: 350px;
  }
  .announcementTextContainer {
    margin: 3.5rem 0px;
    width: 51%;
  }
}
@media (max-width: 1080px) {
  .announcementTitle {
    font-size: 1.5rem;
  }
  .announcementContent {
    font-size: 13px;
  }
  .announcements {
    height: 350px;
  }
  .announcementTextContainer {
    margin: 3.5rem 0px;
    width: 51%;
  }
}
@media (max-width: 1000px) {
  .containerCommon {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 1000px) {
  .containerCommon {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .containerWrapper {
    width: 100%;
  }
  .contentContainer {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .announcementsLogo {
    display: none;
  }
  .announcementContainer {
    display: block;
  }
  .announcementTextContainer {
    margin: 30px 0;
    width: 100%;
  }
  .announcementTitle {
    font-size: 24px;
  }
  .announcementContent {
    font-size: 16px;
  }
}
.announcementText {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
}

.announcementImgContainer {
  width: 49%;
}

@media (max-width: 1920px) {
  .marginX {
    margin: 0 35rem;
  }
  .announcements {
    padding: 0 9rem;
  }
  .announcementsLogo {
    top: 2rem;
  }
  .largeContainer {
    margin: 0 35rem;
  }
  .easyFooter {
    margin: 0 35rem;
  }
}

@media (max-width: 1770px) {
  .marginX {
    margin: 0 28rem;
  }
  .announcements {
    padding: 0 9rem;
  }
  .announcementsLogo {
    top: 2rem;
  }
  .largeContainer {
    margin: 0 28rem;
  }
  .easyFooter {
    margin: 0 28rem;
  }
}

@media (max-width: 1610px) {
  .marginX {
    margin: 0 23rem;
  }
  .announcements {
    padding: 0 9rem;
  }
  .announcementsLogo {
    top: 2rem;
  }
  .largeContainer {
    margin: 0 23rem;
  }
  .easyFooter {
    margin: 0 23rem;
  }
}

@media (max-width: 1500px) {
  .marginX {
    margin: 0 21.2rem;
  }
  .announcements {
    padding: 0 9rem;
  }
  .announcementsLogo {
    top: 2rem;
  }
  .largeContainer {
    margin: 0 21.2rem;
  }
  .easyFooter {
    margin: 0 21.2rem;
  }
}
@media (max-width: 1380px) {
  .marginX {
    margin: 0 18rem;
  }
  .announcements {
    padding: 0 4rem;
  }
  .largeContainer {
    margin: 0 18rem;
  }
  .easyFooter {
    margin: 0 18rem;
  }
}
@media (max-width: 1048px) {
  .marginX {
    margin: 0 12rem;
  }
  .announcements {
    padding: 0 4rem;
  }
  .largeContainer {
    margin: 0 12rem;
  }
  .easyFooter {
    margin: 0 12rem;
  }
}
@media (max-width: 920px) {
  .marginX {
    margin: 0 10rem;
  }
  .announcements {
    padding: 0 4rem;
  }
  .largeContainer {
    margin: 0 10rem;
  }
  .easyFooter {
    margin: 0 10rem;
  }
}
@media (max-width: 855px) {
  .marginX {
    margin: 0 7rem;
  }
  .announcements {
    padding: 0 4rem;
  }
  .largeContainer {
    margin: 0 7rem;
  }
  .easyFooter {
    margin: 0 7rem;
  }
}
@media (max-width: 655px) {
  .marginX {
    margin: 0 3rem;
  }
  .announcements {
    padding: 0 4rem;
  }
  .largeContainer {
    margin: 0 3rem;
  }
  .easyFooter {
    margin: 0 3rem;
  }
}
@media (max-width: 576px) {
  .profileLogo {
    height: 45px;
    width: 45px;
  }
  .profileUserName {
    font-size: 16px;
  }
  .largeContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 545px) {
  .marginX {
    margin: 0 1rem;
  }
  .announcements {
    padding: 0 3rem;
  }
  .largeContainer {
    margin: 0 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .easyFooter {
    margin: 0 1rem;
  }
}
.isBuyFlow {
  position: relative;
  bottom: 70px;
}
