.secondaryButton {
  background-color: var(--bs-white);

  &:hover {
    color: inherit;
    background-color: var(--bs-white);
  }

  &:active {
    /* !important is required because bootstrap's rule is more specific */
    color: inherit !important;
    background-color: var(--bs-white) !important;
  }
}