// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@font-face {
    font-family: 'Sofia Pro';
    src: url('../src/fonts/Sofia Pro Light Az.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Sofia Pro Bold';
    src: url('../src/fonts/Sofia Pro Semi Bold Az.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Sofia Pro Black';
    src: url('../src/fonts/Sofia Pro Medium Az.otf');
    font-weight: normal;
    font-style: normal;
}

@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
$font-family-sans-serif: "Sofia Pro", sans-serif;

$primary: #000000;
$danger: #EF373E;
$link-color: #0365BF;

$input-focus-box-shadow: none;
$input-focus-border-color: unset;
$enable-validation-icons: false;

$form-select-indicator: none;

$form-check-input-checked-bg-color: var(--bs-white);
$form-check-input-checked-bg-image: none;

$btn-font-weight: 600;
$btn-border-radius: 4px;
$btn-padding-y: 11px;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/progress";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";
